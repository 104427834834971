import { Component, DestroyRef, Inject, PLATFORM_ID, TemplateRef, inject, viewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NotificationsService } from './core/services/notifications.service';
import { PopoverMessage } from './core/class/popover-message';
import { ApiError } from './core/class/api-error';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { concatMap, filter, from, takeWhile } from 'rxjs';
import { AuthService } from './core/services/auth.service';
import { Metrika } from 'ng-yandex-metrika';
import { NavigationEnd, Router } from '@angular/router';
import { isPlatformServer, Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  /** отображение модалки */
  public showModal = false;

  /** текст модалки */
  public modalText = '';

  /** текст заголовок */
  public modalTitle = '';

  /** комментарий для заголовка */
  public commentText = '';

  /** отображение модалки */
  public isTimeout = false;

  private errorsModal = viewChild<TemplateRef<any>>('errorsModal');
  private _errorsModalRef: MatDialogRef<TemplateRef<any>>;

  public dataLoading: boolean = false;

  private _destroyRef = inject(DestroyRef);
  constructor(
    private metrika: Metrika,
    private _notificationsService: NotificationsService,
    private _matDialog: MatDialog,
    private _authService: AuthService,
    private router: Router,
    public translateService: TranslateService,
    location: Location,
    @Inject(PLATFORM_ID) platformId: Object,
  ) {
    this.translateService.addLangs(["ru","tgk","uzb","kir"]);
    if (isPlatformServer(platformId)) {
      return;
    }

    let prevPath = location.path();
    this.router
      .events
      .pipe(filter(event => (event instanceof NavigationEnd)))
      .subscribe(() => {
        const newPath = location.path();
        this.metrika.hit(newPath, {
          referer: prevPath,
          callback: () => { console.log('hit end'); }
        });
        prevPath = newPath;
      });
  }

  public ngOnInit () {
    // подписываемся на событие возникновения ошибки при запросах к апи и открываем модалки последовательно после закрытия онных
    this._notificationsService.errorsChannel.pipe(
      filter(value => !!value),
      concatMap(value => {
        this._onEmitError(value as ApiError);
        return this._errorsModalRef.afterClosed();
      }),
      takeUntilDestroyed(this._destroyRef),
    ).subscribe(),
      (err) => { console.log(err)};

    // подписываемся на событие различных сообщений пользователю
    this._notificationsService.messagesChannel.pipe(
      filter(value => !!value),
      concatMap(value => {
        this._onGetMessage(value);
        return this._errorsModalRef.afterClosed();
      }),
      takeUntilDestroyed(this._destroyRef),
    ).subscribe(),
    (err) => { console.log(err)};

    this._authService.onTimeout
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe(() => {
        this.modalTitle = `Внимание`;
        this.modalText = `Время сессии истекло, вы будете перенаправлены на начальный этап`;
        this.isTimeout = true;
        this.openErrorModal();
      });
  }

  public openErrorModal() {
    this._errorsModalRef = this._matDialog.open(this.errorsModal());
    if (!!this.isTimeout) {
      this._errorsModalRef.afterClosed().pipe(takeWhile(() => !!this.isTimeout)).subscribe(() => {
        this._authService.removeToken(`${this._authService.tokenDetails.timeoutUrl}`);
      });
    }
  }

  public closeErrorModal() {
    this._errorsModalRef && this._errorsModalRef.close(true);
  }

    /**
   * обработчик сообщения из канала
   * @param message данные сообщения
   */
    private _onGetMessage(message: PopoverMessage): void {
      if (!message) return;
      this.modalTitle = message.title || `Внимание`;
      this.modalText = message.additional || `Ошибка`;
      this.commentText = null;
      this.openErrorModal();
    }
  
    /**
     * обработчик ошибки апи
     * @param error ошибка
     */
    private _onEmitError(error: ApiError): void {
      if (!error) return;
      switch (error.code) {
        case '401': // unauthorized
          // проходим флоу авторизации

          break;
        default: {
          this.modalTitle = error.title || `Внимание`;
          this.modalText = error.fullMessage.message || `Ошибка`;
          this.commentText = error.fullMessage.comment || `Ошибка`;
          this.openErrorModal();
          break;
        }
      }
    }
}
