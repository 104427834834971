import { APP_INITIALIZER, ErrorHandler, inject, LOCALE_ID, NgModule } from "@angular/core";
import { CommonModule, registerLocaleData } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import localeRu from '@angular/common/locales/ru';
import localeRuExtra from '@angular/common/locales/extra/ru';
import moment from "moment";
import { appRouter } from "./app.routes";
import { AuthInterceptor } from "./core/services/interceptors/auth.interceptor";
import { HttpErrorInterceptor } from "./core/services/interceptors/http-error.interceptor";
import { MatPaginatorIntl } from "@angular/material/paginator";
import { getPaginatorIntl } from "./core/misc/paginator-intl";
import { AppComponent } from "./app.component";
import { AuthService } from "./core/services/auth.service";
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from "@angular/material/button";
import { MetrikaModule } from "ng-yandex-metrika";
import { SearchStore } from "./core/stores/search.store";
import { GoogleTagManagerService } from "angular-google-tag-manager";
import { TranslateDirective, TranslateLoader, TranslateModule, TranslatePipe } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";

registerLocaleData(localeRu, 'ru-RU', localeRuExtra);
moment.locale('ru');

export function loadUserFactory(authService: AuthService) {
  return function () {
    if (authService.loadTokenIfExists()) {
        return Promise.resolve(null);
    } else {
      authService.removeToken();
      return Promise.resolve(null);
    }
  };
}

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

class MyErrorHandler implements ErrorHandler {
  handleError(error) {
    console.log(window.location);
    console.log(error);
    const gtmService = inject(GoogleTagManagerService);
    const gtmTag = {
      event: 'jserror',
      data: error
    };
    gtmService.pushTag(gtmTag);
  }
}


@NgModule({ declarations: [AppComponent],
    bootstrap: [AppComponent],
    imports: [
      CommonModule,
      BrowserModule,
      BrowserAnimationsModule,
      appRouter,
      MatDialogModule,
      MatButtonModule,
      MetrikaModule.forRoot(
        { id: 98354382, webvisor: true },
      ),
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
        },
        defaultLanguage: "ru"
      }),
      TranslatePipe,
      TranslateDirective,
    ],
    providers: [
      { provide: LOCALE_ID, useValue: 'ru-RU' },
      {
          provide: APP_INITIALIZER,
          useFactory: loadUserFactory,
          multi: true,
          deps: [AuthService],
      },
      // добовляем токен при запросе
      { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
      // перехват ошибок апи с бека
      { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
      { provide: MatPaginatorIntl, useValue: getPaginatorIntl() },
      SearchStore,
      provideHttpClient(withInterceptorsFromDi()),
      { provide: "googleTagManagerId", useValue: "GTM-5J37WT48" },
      GoogleTagManagerService,
      {provide: ErrorHandler, useClass: MyErrorHandler}
  ] })
export class AppModule {}