import { MatPaginatorIntl } from '@angular/material/paginator';

/** кастомизация лейблов для пагинации матириала */
export function getPaginatorIntl() {
  const paginatorIntl = new MatPaginatorIntl();

  paginatorIntl.itemsPerPageLabel = `Показать на странице:`;
  paginatorIntl.nextPageLabel = `На следующую страницу`;
  paginatorIntl.previousPageLabel = `На предыдущую страницу`;
  paginatorIntl.lastPageLabel = `На последнюю страницу`;
  paginatorIntl.firstPageLabel = `На первую страницу`;

  /** формируем описание пагинацыии "1 - 8 из 8"
   * page: страница
   * pageSize: размер страницы
   * length: всего
   * */
  paginatorIntl.getRangeLabel = (page: number, pageSize: number, length: number) => {
    if (length === 0 || pageSize === 0) {
      return `0 из ` + length;
    }

    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex =
      startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
    return startIndex + 1 + ' - ' + endIndex + ` из ` + length;
  };

  return paginatorIntl;
}
