import { ActivatedRouteSnapshot, CanActivateFn, PreloadAllModules, Router, RouterModule, RouterStateSnapshot, Routes } from '@angular/router';
import { ErrorPageComponent } from './components/error-page/error-page.component';
import { inject } from '@angular/core';
import { AuthService, PROJECT_TYPE } from './core/services/auth.service';
import { SuccessComponent } from './components/success/success.component';
import { FaildComponent } from './components/faild/faild.component';
import { HttpClient } from '@angular/common/http';
import { HotelSearchComponent } from './hotel-module/hotel-search/hotel-search.component';
import { HotelListComponent } from './hotel-module/hotel-list/hotel-list.component';
import { hotelRoutes } from './hotel-module/routes';
import { toLower } from 'lodash';

const canActivateQueryParams: CanActivateFn = (  route: ActivatedRouteSnapshot,  state: RouterStateSnapshot,) => {
  const router = inject(Router);
  const authService = inject(AuthService);
  if (authService.getToken()){
    return true;
  }
  // ?guid=70d81264-008d-4b59-8415-b5233d184279&clientId=70d81264-008d-4b59-8415-b5233d184279&successTenantUrl=partner-ui.travelpublic.ru&failTenantUrl=partner-ui.travelpublic.ru
  if ((Object.keys(route.queryParams).length)) {
    console.log(route.queryParams)
    return authService.auth(route.queryParams);
  } else {
    const http = inject(HttpClient);
    let token = http.get(`/v1/settings/authorize?clientId=321312&successTenantUrl=https://travelb2b-ui.dev.vkomandirovke.online&failTenantUrl=https://travelb2b-ui.dev.vkomandirovke.online&SessionTimeoutUrl=https://travelb2b-ui.dev.vkomandirovke.online&type=air`,  {responseType: 'text',headers: {
      'Api-Key': `048ad47f-fddf-4181-b280-d8951083e397`,
    }}).toPromise().then(() => {
      // 
    });
    // router.navigate(['/error']);
    return false;
  }
};

const canActivatePaymentParams: CanActivateFn = (  route: ActivatedRouteSnapshot,  state: RouterStateSnapshot,) => {
  const router = inject(Router);
  if (!!route.queryParams['paymentStatus']) {
      return true;
    } else {
      router.navigate(['/air-search']);
      return false;
    }
};

const canActivateServiceType: CanActivateFn = (  route: ActivatedRouteSnapshot,  state: RouterStateSnapshot,) => {
  const authService = inject(AuthService);
  const router = inject(Router);
  if (!authService?.tokenDetails) {
    router.navigate([`/`]);
    return false;
  }
  if (route.data['type'] === authService.tokenDetails?.serviceType) {
    return true;
  } else {
    router.navigate([`/${authService.tokenDetails?.serviceType}-search`]);
    return false;
  }
}

export const routes: Routes = [
  { path: 'error', component: ErrorPageComponent },
  { path: 'payment-success', canActivate: [canActivatePaymentParams], component: SuccessComponent },
  { path: 'payment-faild', canActivate: [canActivatePaymentParams], component: FaildComponent }, 
  // { path: 'hotel-search', canActivate: [canActivateQueryParams], component: HotelSearchComponent },
  // { path: 'hotel-search/list', canActivate: [canActivateQueryParams], component: HotelListComponent },
  {
    path: '',
    canActivate: [canActivateQueryParams, canActivateServiceType],
    data: {
      type: PROJECT_TYPE.HOTEL 
    },
    children: [
      { path: '', redirectTo: 'hotel-search', pathMatch: 'full' },
      {
        path: 'hotel-search',
        loadChildren: () => import('./hotel-module/routes').then(m => m.hotelRoutes),
      },
    ],
  },
  {
    path: '',
    data: {
      type: PROJECT_TYPE.AIR 
    },
    canActivate: [canActivateQueryParams, canActivateServiceType],
    children: [
      { path: '', redirectTo: 'air-search', pathMatch: 'full' },
      {
        path: 'air-search',
        loadChildren: () => import('./air-module/air.module').then(m => m.AirModule),
      },
    ],
  },
];

export const appRouter = RouterModule.forRoot(routes, {
  preloadingStrategy: PreloadAllModules,
  onSameUrlNavigation: 'reload',
  paramsInheritanceStrategy: 'always',
  scrollPositionRestoration: 'enabled',
});