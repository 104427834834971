<router-outlet></router-outlet>

<ng-template #errorsModal>
  <div class="dialog-body">
    <div class="width-full">
      @if (!!modalTitle) {
        <div class="title">{{ modalTitle }}</div>
      }

      <div class="text-center" mat-dialog-content>
        {{ modalText }}
      </div>
      @if (!!commentText) {
        <div class="text-center text-gray" mat-dialog-content>
          {{ commentText }}
        </div>
      }
    </div>
    @if (!!isTimeout) {
      <div class="dialog-footer row" mat-dialog-actions>    
        <button
          mat-raised-button
          class="d-block mat-btn mat-btn-main search-button-width"
          (click)="closeErrorModal()">
          Понятно
        </button>
      </div>
    }
    <img
      (click)="closeErrorModal()"
      class="dialog-close"
      src="assets/images/close.svg"
      alt="Закрыть"/>
  </div>
</ng-template>